

import {anchorLinkScroll, isInViewport,} from './modules/helpers.js';

// Take some useful functions
import {
	closest_polyfill,
} from './modules/helpers.js';

// Tabs functionality (uncomment it if you need it)
import { tabsNavigation } from './modules/navi-tabs';


window.addEventListener("DOMContentLoaded", function() {
	
	
	
	/*[].forEach.call( document.querySelectorAll('.phone_mask'), function(input) {
	 var keyCode;
	 function mask(event) {
	 event.keyCode && (keyCode = event.keyCode);
	 var pos = this.selectionStart;
	 if (pos < 3) event.preventDefault();
	 var matrix = "+3 (___) ___ __ __",
	 i = 0,
	 def = matrix.replace(/\D/g, ""),
	 val = this.value.replace(/\D/g, ""),
	 new_value = matrix.replace(/[_\d]/g, function(a) {
	 return i < val.length ? val.charAt(i++) : a
	 });
	 i = new_value.indexOf("_");
	 if (i != -1) {
	 i < 5 && (i = 3);
	 new_value = new_value.slice(0, i)
	 }
	 var reg = matrix.substr(0, this.value.length).replace(/_+/g,
	 function(a) {
	 return "\\d{1," + a.length + "}"
	 }).replace(/[+()]/g, "\\$&");
	 reg = new RegExp("^" + reg + "$");
	 if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) {
	 this.value = new_value;
	 }
	 if (event.type == "blur" && this.value.length < 5) {
	 this.value = "";
	 }
	 }
	 
	 input.addEventListener("input", mask, false);
	 input.addEventListener("focus", mask, false);
	 input.addEventListener("blur", mask, false);
	 input.addEventListener("keydown", mask, false);
	 
	 });*/
	
});

/**
 * All custom code is wrapped in IIFE function
 * to prevent affect our code to another parts of code
 */
;(function ( $ ) {

	/**
	 * PLease Collect here all variables with DOM elements
	 * Use const for all DOM elements and type it as UPPERCASE text
	 * It will help to each developer understand that it's a const not a variable
	 */

	//const EXAMPLE = document.querySelector('.js-preloader-wrapper');
	const openPopup = document.querySelectorAll('.js-popup-activator');
	const closePopupButton = document.querySelectorAll('.js-close-popup');
	const body = document.querySelector('body');
	const hamburger = document.querySelectorAll('.js-hamburger');
	const closeMenu = document.querySelector('.js-close-menu');
	const mobileMenu = document.querySelector('.js-mobile-menu');
	const scrollTop = document.querySelector('.js-scroll-top');
	const successMess = document.querySelector('#success-popup');
	const forms = document.querySelectorAll( '.wpcf7' );
	const animateBlock = document.querySelectorAll( '.js-is-animate' );
	const popups = document.querySelectorAll( '.js-popup' );
	const acceptance = document.querySelectorAll( '.js-acceptance' );
	const formBookParty = document.querySelector( '#form-book-party' );
	const tableItems = document.querySelectorAll( '.js-table-item');
	const reserveForm = document.querySelector('#reservation');
	const reserveTableForm = document.querySelector('#reserve-table-form');
	const reserveDateInput = document.querySelector('.js-reservation-input-date');
	const timeFrom = document.querySelector('.js-reservation-from-time');
	const timeTo = document.querySelector('.js-reservation-to-time');
	const error =  document.querySelector('.js-reservation-error');
	const removeReservation =  document.querySelectorAll('.js-remove-reservation');
	const adminFilter =  document.querySelector('#admin-filter');
	const filterLoader =  document.querySelector('.js-filer-loader');
	const adminTable =  document.querySelectorAll('.js-admin-table');
	const filterReset =  document.querySelector('.js-filter-reset');
	const filterError =  document.querySelector('.js-filter-error');
	const adminReserveForm =  document.querySelector('#reserve-admin-table-form');
	const adminReservePopup = document.querySelector('#reserve-admin-table');
	const checkError    = adminReserveForm.querySelector('.js-check-error');
	const gallerySlider = document.querySelectorAll('.js-gallery-slider');
	const productPopupLink = document.querySelectorAll('.js-desc-popup');
	const productPopup = document.querySelector('#product-popup');
	const foodWrap = document.querySelectorAll('.js-food-wrap');
	const foodMenu = document.querySelector('.js-food-menu');
	const topPage = document.querySelector('.page-top');
	const foodMenuItem = document.querySelectorAll('.js-food-menu-item');
	const foodRow = document.querySelectorAll('.js-food-row');
	const headerDummi = document.querySelectorAll('.site-header .js-dummi-link>a');
	const headerMobileDummi = document.querySelectorAll('.mobile-menu__menu .js-dummi-link>a');
	const editReserveForm = document.querySelector('#edit-reserve-table');
	const monthWrap = document.querySelector('.js-month-wrap');
	const yearWrap = document.querySelector('.js-year-wrap');
	const daysWrap = document.querySelector('.js-days-wrap');
	const editPopup = document.querySelector('#edit-admin-table');
	let isScrolling = false;
	let isClickMenu = false;
	const faqOpen = document.querySelectorAll('.js-faq-open');
	const tableMultiselect = $('.js-table-multiselect');
	const disableVariant = document.querySelector('.js-disable-variant');
	const disableForm = document.querySelector('#disable-table-form');
	const removeDisableForm = document.querySelector('#remove-disable-table-form');

	removeDisableForm && removeDisableForm.addEventListener('submit', event => {
		event.preventDefault();

		const data = new FormData(event.target);
		data.append( 'action', 'remove_date_disable_table' );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((responseData) => {
				if(responseData.success) {
					const allElem = document.querySelectorAll(`td.disable-table`);

					allElem.forEach(item => {
						item.classList.remove('active');
					})

					const disabledArrIds = responseData.data;
					if(disabledArrIds.length) {
						disabledArrIds.forEach( item => {
							const disabledElem = document.querySelector(`td.disable-table[data-table="${item}"]`);

							if(disabledElem) {
								disabledElem.classList.add('active');
							}
						})
					}
				}
				closePopup();
			})
	})

	disableForm && disableForm.addEventListener('submit', event => {
		event.preventDefault();

		const data = new FormData(event.target);
		data.append( 'action', 'disable_table' );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((responseData) => {
				const error = document.querySelector('.js-disable-table-error');
				if(responseData.success) {
					const disabledArrIds = responseData.data;
					if(disabledArrIds.length) {
						disabledArrIds.forEach( item => {
							const disabledElem = document.querySelector(`td.disable-table[data-table="${item}"]`);

							if(disabledElem) {
								disabledElem.classList.add('active');
							}
						})
					}

					error.innerText = ''
				} else {
					error.innerText = responseData.data
				}

				closePopup();
			})
	})

	disableVariant && disableVariant.addEventListener('change', (event) => {
		event.preventDefault();

		const closesForm = event.target.closest('#disable-table-form');
		const disableDayVariant = closesForm.querySelector('.js-disable-days');
		const selectVal = event.target.value;

		if(selectVal === 'days') {
			disableDayVariant.style.display = 'block';
		} else {
			disableDayVariant.style.display = 'none';
		}

	})
	
	if(tableMultiselect.length) {
		tableMultiselect.select2({
			placeholder: "Select tables",
			allowClear: true
		});
	}

	faqOpen && faqOpen.forEach( (item) => {
		item.addEventListener('click', (event) => {
			event.preventDefault();

			event.target.classList.toggle('open');
			const parent = event.target.closest('.js-faq-item');
			const content = parent.querySelector('.js-faq-answer');
			$(content).slideToggle();

		})
	});
	
	const timepicker = document.querySelectorAll('.timepicker');
	timepicker && timepicker.forEach( item => {
		$(item)?.timepicker({
			step: 15,
			timeFormat: 'H:i',
			minTime: '12',
			maxTime: '21',
			disableTextInput: true,
			disableTouchKeyboard: true,
			appendTo: $(item).parent()
		});
	})

	const timepickerTo = document.querySelectorAll('.timepicker.js-reservation-to-time');
	timepickerTo && timepickerTo.forEach( item => {
		$(item)?.timepicker({
			step: 15,
			timeFormat: 'H:i',
			minTime: '12',
			maxTime: '23',
			disableTextInput: true,
			disableTouchKeyboard: true,
			appendTo: $(item).parent()
		});
	})

	editReserveForm && editReserveForm.addEventListener('submit',  event => {
		event.preventDefault();

		const currentDate = event.target.querySelector('input[name="current_data"]').value;
		const dateArr = currentDate.split('-');
		const day = dateArr[2];
		const year = dateArr[0];
		const month = dateArr[1];
		const status = document.querySelector('.js-admin-wrap').dataset.status;
		const error = event.target.querySelector('.js-check-error');

		const data = new FormData(event.target);
		data.append( 'action', 'edit_reserve_table' );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((responseData) => {
				if(responseData.success) {
					error.classList.remove('show');
					getTableReserves (day, month, year, status);
					editPopup && editPopup.classList.remove('show');
					addScroll();
				} else {
					const reserveId = responseData.data.reserve;
					error.innerText = `The table is already reserved for this date. Reservation ID - ${reserveId}`;
					error.classList.add('show');
				}
			})
	})

	headerMobileDummi && headerMobileDummi.forEach(item => {
		item.addEventListener('click', event => {
			event.preventDefault();

			const parent = event.target.closest('li.menu-item-has-children');
			if(!parent) return;
			const subMenu = parent.querySelector('.sub-menu');

			subMenu && subMenu.classList.toggle('open');
		})
	})

	headerDummi && headerDummi.forEach(elem => {
		elem.addEventListener('click', event => {
			event.preventDefault();
		})
	})

	foodMenuItem && foodMenuItem.forEach(elem => {
		elem.addEventListener('click', event => {
			event.preventDefault();
			const elemId = event.target.getAttribute('href');
			const targetElement = document.querySelector(`${elemId}`);
			const offset = 150;
			const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
			const offsetPosition = elementPosition - offset;

			foodMenuItem.forEach(elem => {
				elem.classList.remove('active');
			})

			event.target.classList.add('active');

			isClickMenu = true;

			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth'
			});

		})
	})

	fullItemDetect();

	let pointProp;

	window.addEventListener('scroll', function() {
		const scroll = window.pageYOffset;
		let isScrolling = true;

		clearTimeout(scrollEndTimer);

		let scrollEndTimer = setTimeout(function() {
			if (isScrolling) {
				isScrolling = false;
				isClickMenu = false
			}
		}, 600);

		if(foodRow) {
			foodRow.forEach(elem => {
				const top = elem.offsetTop - 200;
				const bottom = top + elem.clientHeight;

				if (scroll >= top && scroll <= bottom) {
					 const pointId = elem.getAttribute('id');
					 const currentElemMenu = document.querySelector(`.js-food-menu-item[href="#${pointId}"]`);


					 if(!isClickMenu) {
						 foodMenuItem.forEach(elem => {
							 elem.classList.remove('active');
						 })

						 const foodMenu = document.querySelector('.js-scroll-menu');
						 let containerRect = foodMenu.getBoundingClientRect();
						 let activeOptionRect = currentElemMenu.getBoundingClientRect();

						 if(pointProp != pointId) {
							 if (containerRect.left + containerRect.width < activeOptionRect.left + activeOptionRect.width) {

								 let scrollAmount = activeOptionRect.left + activeOptionRect.width - (containerRect.left + containerRect.width);
								 foodMenu.scrollTo({
									 left: foodMenu.scrollLeft + scrollAmount,
									 behavior: 'smooth'
								 });
							 } else if (activeOptionRect.left < containerRect.left) {

								 let scrollAmount = containerRect.left - activeOptionRect.left;
								 foodMenu.scrollTo({
									 left: foodMenu.scrollLeft - scrollAmount,
									 behavior: 'smooth'
								 });
							 }
						 }

						 currentElemMenu && currentElemMenu.classList.add('active');

						 pointProp = pointId;

					 }
				}
			})
		}


		if(foodMenu && topPage) {
			const topPageHeight = topPage.clientHeight;

			if (scroll >= (topPageHeight-82)) {
				foodMenu.classList.add('sticki');
			} else {
				foodMenu.classList.remove('sticki');
			}
		}

	});

	window.addEventListener('resize', function() {
		fullItemDetect();
	});

	function fullItemDetect() {
		foodWrap && foodWrap.forEach(elem => {
			const fullItem = elem.querySelectorAll('.full');
			const mediaWidth = window.screen.width;

			if(fullItem.length && mediaWidth <= 11024 && fullItem.length % 2 !== 0) {
				const calcNextEl = fullItem.length+1;
				const currentEl = elem.querySelector(`.js-food-item:nth-child(${calcNextEl})`)
				currentEl && currentEl.classList.add('align-top');
			}

		});
	}

	closeMenu && closeMenu.addEventListener('click', event => {
		event.preventDefault();
		mobileMenu && mobileMenu.classList.remove('show');
		addScroll();

	})

	hamburger && hamburger.forEach(elem => {
		elem.addEventListener('click', event => {
			event.preventDefault();
			mobileMenu && mobileMenu.classList.add('show');
			removeScroll();
		})
	})

	productPopupLink && productPopupLink.forEach(elem => {
		elem.addEventListener('click', event => {
			event.preventDefault();

			if(!productPopup) return;
			const popupTitle = productPopup.querySelector('.js-product-title');
			const popupImage = productPopup.querySelector('.js-product-image');
			const popupPrice = productPopup.querySelector('.js-product-price');
			const popupDesc = productPopup.querySelector('.js-product-desc');
			const popupComponents = productPopup.querySelector('.js-product-components');

			const parent = event.target.closest('.js-food-item');
			const imageUrl = event.target.getAttribute('href');
			const foodTitle = parent.querySelector('.js-food-title');
			const foodPrice = parent.querySelector('.js-food-price');
			const foodDesc = parent.querySelector('.js-food-desc');
			const foodComponents = parent.querySelector('.js-food-components');

			if(imageUrl) {
				popupImage.src = imageUrl;
			}

			if(foodTitle) {
				popupTitle.innerText = foodTitle.innerText;
			} else {
				popupTitle.innerText = '';
			}

			if(foodPrice) {
				popupPrice.innerText = foodPrice.innerText;
			} else {
				popupPrice.innerText = '';
			}

			if( foodDesc ) {
				popupDesc.innerText = foodDesc.innerText;
			} else {
				popupDesc.innerText = '';
			}

			if (foodComponents) {
				popupComponents.innerText = foodComponents.innerText;
			} else {
				popupComponents.innerText = '';
			}

			setTimeout(() => {
				productPopup.classList.add('show');
				removeScroll();
			}, 300);
		})
	})

	gallerySlider && gallerySlider.forEach(item => {
		$(item).slick({
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			centerMode: true,
			arrows: true,
			variableWidth: true,
			adaptiveHeight: true,
			//autoplay: true,
			//autoplaySpeed: 3000,
			//lazyLoad: 'ondemand',
			responsive: [
				{
					breakpoint: 480,
					settings: {
						centerMode: false,
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: false,
					}
				}
			]
		});
	})
	
	const itiInstances = [];
	
	const inputsPhone = document.querySelectorAll(".phone_mask");
	inputsPhone.forEach(input => {
		const itiInstance = window.intlTelInput(input, {
			utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js",
			initialCountry: "ie",
			useFullscreenPopup: false,
		});
		
		itiInstances.push(itiInstance);
	});
	
	function resetCountryAfterAjax() {
		itiInstances.forEach(iti => {
			iti.setCountry("ie");
		});
	}

	adminReserveForm && adminReserveForm.addEventListener('submit', async (event) => {
		event.preventDefault();
		const form  = event.target;
		const tableSelect = form.querySelector('.js-table-multiselect');
		const validAdminReserveForm = await validAdminReserve();

		if(validAdminReserveForm) {
			checkError && checkError.classList.remove('show');
			const data = new FormData(form);
			data.append( 'action', 'admin_reserve_table' );

			fetch(var_from_php.ajax_url,
				{
					method: "POST",
					credentials: 'same-origin',
					body: data
				})
				.then((response) => response.json())
				.then((response) => {
					if(response.data.send) {
						form.reset();
						resetCountryAfterAjax();
						if(tableSelect) {
							$(tableSelect).val(null).trigger('change');
						}
						
						const adminTable = document.querySelector('.js-admin-table');
						adminReservePopup && adminReservePopup.classList.remove('show');
						addScroll();
						if(adminTable) {
							const currentDate = adminTable.dataset.date;
							const dateArr = currentDate.split('-');
							const day = dateArr[2];
							const year = dateArr[0];
							const month = dateArr[1];
							getTableReserves (day, month, year);
						}
					}
				})
		}

	})

	filterReset && filterReset.addEventListener('click', event => {
		event.preventDefault();

		const data = new FormData();
		data.append( 'action', 'reset_filter' );
		event.target.disabled = true;
		adminFilter && adminFilter.reset();
		filterLoader && filterLoader.classList.add('show');
		filterError && filterError.classList.remove('show');
		adminTable && adminTable.forEach(elem => {
			elem.classList.remove('active');
		})

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((response) => {

				if(response.success ) {
					const resultElem = document.querySelector('.js-admin-result');
					const tablesArr = response.data.tables;

					if(tablesArr) {
						tablesArr.forEach( item => {
							const tableElem = document.querySelector(`.table-${item}`);

							tableElem && tableElem.classList.add('active');
						})
					}

					if(!resultElem) return;

					if(response.data.content) {
						resultElem.innerHTML = response.data.content;
					} else {
						resultElem.innerHTML = '<p class="no-reserved">No reserved tables found!</p>';
					}

				}

				filterLoader && filterLoader.classList.remove('show');
				event.target.disabled = false;
			})
	})

	adminFilter && adminFilter.addEventListener('submit', event => {
		event.preventDefault();
		let isValid = true;
		const dataInput = event.target.querySelector('.js-admin-input-date');

		if( !dataInput.value ) {
			isValid = false;

			if(filterError) {
				filterError.innerText = 'The date field is required!';
				filterError.classList.add('show');
			} else {
				filterError && filterError.classList.remove('show');
			}
		}

		if(isValid) {
			const data = new FormData(event.target);
			data.append( 'action', 'search_reservation' );
			const submitButton = event.target.querySelector('.js-filter-submit');
			filterError && filterError.classList.remove('show');

			adminTable && adminTable.forEach(elem => {
				elem.classList.remove('active');
			})

			if(submitButton) submitButton.disabled = true;

			filterLoader && filterLoader.classList.add('show');

			fetch(var_from_php.ajax_url,
				{
					method: "POST",
					credentials: 'same-origin',
					body: data
				})
				.then((response) => response.json())
				.then((response) => {

					if(response.success ) {
						const resultElem = document.querySelector('.js-admin-result');
						const tablesArr = response.data.tables;

						if(tablesArr) {
							tablesArr.forEach( item => {
								const tableElem = document.querySelector(`.table-${item}`);

								tableElem && tableElem.classList.add('active');
							})
						}

						if(!resultElem) return;

						if(response.data.content) {
							resultElem.innerHTML = response.data.content;
						} else {
							resultElem.innerHTML = '<p class="no-reserved">No reserved tables found!</p>';
						}

					}

					filterLoader && filterLoader.classList.remove('show');
					if(submitButton) submitButton.disabled = false;
				})
		}

	})

	removeReservation && removeReservation.forEach(item => {
		item.addEventListener('click', event => {
			event.preventDefault()
			const postId = event.target.dataset.id;

			if(postId) {
				const data = new FormData();
				data.append( 'action', 'remove_reservation' );
				data.append( 'post_id', postId );

				fetch(var_from_php.ajax_url,
					{
						method: "POST",
						credentials: 'same-origin',
						body: data
					})
					.then((response) => response.json())
					.then((response) => {

						if(response.success) {
							const postId = response.data.post_id;
							const post = document.querySelector(`.post-${postId}`);
							post && post.remove();
						}
					})
			}


		})
	})

	reserveTableForm && reserveTableForm.addEventListener('submit', event => {
		event.preventDefault();
		let isValid = true;
		const form = event.target;

		const nameInput   = form.querySelector('input[name="name"]');
		const emailInput  = form.querySelector('input[name="email"]');
		const phoneInput  = form.querySelector('input[name="phone"]');
		const adultsSelect = form.querySelector('select[name="adults"]');
		const childrenSelect = form.querySelector('select[name="children"]');
		const personError = form.querySelector('.js-person-error');
		const dateInput   = form.querySelector('input[name="date"]');
		const agree = form.querySelector('.acceptance');
		const errorAgree = agree.closest('.checkbox-wrap')?.querySelector('.js-check-error');

		const adults = parseInt(adultsSelect.value);
		const children = parseInt(childrenSelect.value);
		const maxPlace    = parseInt(form.dataset.place);

		if(!dateInput.value) {
			isValid = false;
		}
		
		if(!agree.checked) {
			isValid = false;
			if(errorAgree) {
				errorAgree.classList.add('show');
			}
		} else {
			errorAgree.classList.remove('show');
		}

		if( (children+adults) >  maxPlace) {
			isValid = false;
			personError.querySelector('span').innerText = maxPlace;
			personError && personError.classList.add('show');
		} else {
			personError && personError.classList.remove('show');
		}

		if(!nameInput.value) {
			isValid = false;
			nameInput.classList.add('error');
		} else {
			nameInput.classList.remove('error');
		}

		if(!phoneInput.value) {
			isValid = false;
			phoneInput.classList.add('error');
		} else {
			phoneInput.classList.remove('error');
		}

		if(!emailInput.value) {
			isValid = false;
			emailInput.classList.add('error');
		} else {
			emailInput.classList.remove('error');
		}

		if(adultsSelect.value == '0') {
			isValid = false;
			adultsSelect.classList.add('error');
		} else {
			adultsSelect.classList.remove('error');
		}

		if(isValid) {
			const data = new FormData(form);
			data.append( 'action', 'reserve_table' );

			fetch(var_from_php.ajax_url,
				{
					method: "POST",
					credentials: 'same-origin',
					body: data
				})
				.then((response) => response.json())
				.then((response) => {
					if(response.data.send) {
						reserveForm && reserveForm.reset();
						form.reset();
						tableItems && tableItems.forEach(item => {
							item.classList.remove('active');
						})
						openSuccess();
					}
				})
		}

	})

	if(timeTo) {
		$(timeTo).on('change',async function(event) {

			validReserveForm();
			await checkTable();
			if(!reserveTableForm) return;
			const val = event.target.value;
			const toInput =  reserveTableForm.querySelector('input[name="to"]');
			if (toInput) toInput.value = val;
		});
	}

	if(timeFrom) {
		$(timeFrom).on('change',async function(event) {
			validReserveForm();
			await checkTable();
			if(!reserveTableForm) return;
			const val = event.target.value;
			const fromInput =  reserveTableForm.querySelector('input[name="from"]');
			if (fromInput) fromInput.value = val;
		});
	}

	function checkDisabledTables (date) {
		if(!date) return false;

		const data = new FormData();
		data.append( 'action', 'check_disabled_tables' );
		data.append( 'date', date );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((responseData) => {
				if(responseData.success) {
					const allTables = document.querySelectorAll('.js-table-item');

					allTables && allTables.forEach( item => {
						item.classList.remove('disable-table')
					})
					const disabledTables = responseData.data;

					if(disabledTables.length) {
						disabledTables.forEach( item => {
							const elem = document.querySelector(`.js-table-item[data-table="${item}"]`);

							if(elem) {
								elem.classList.add('disable-table')
							}
						})
					}

				}
			})
	}

	reserveDateInput && reserveDateInput.addEventListener('change', async (event) => {
		event.preventDefault();
		validReserveForm();
		await checkTable();
		if(!reserveTableForm) return;
		const val = event.target.value;
		checkDisabledTables(val)
		const dateInput =  reserveTableForm.querySelector('input[name="date"]');
		if (dateInput) dateInput.value = val;
	})

	tableItems && tableItems.forEach(table => {
		table.addEventListener('click', event => {
			event.preventDefault();

			const isValid = validReserveForm();

			if( isValid ) {
				const popup = document.querySelector( '#reserve-table' );
				popup && popup.classList.add('show');
				removeScroll();
			}

		})
	})

	acceptance && acceptance.forEach(item => {
		item.addEventListener('change', event => {
			const form = event.target.closest('form');
			const submitButton = form.querySelector('.js-submit');
			if (event.target.checked) {
				if(submitButton) submitButton.disabled = false;
			} else {
				if(submitButton) submitButton.disabled = true;
			}
		})
	})

	formBookParty && formBookParty.addEventListener('submit', event => {
		event.preventDefault();
		const form         = event.target;
		const loader       = form.querySelector( '.js-loader');
		const nameInput    = form.querySelector( 'input[name="name"]');
		const phoneInput   = form.querySelector( 'input[name="phone"]');
		const emailInput   = form.querySelector( 'input[name="email"]');
		const personsInput = form.querySelector( 'input[name="persons"]');
		const timeInput    = form.querySelector( 'input.timepicker');
		const dateInput    = form.querySelector( 'input[name="date"]');
		const checkError   = document.querySelector('.js-book-party-error');
		const currentDate  = new Date();
		currentDate.setHours(0, 0, 0, 0);

		let validForm = true;

		if (!nameInput.value) {
			validForm = false;
			nameInput.classList.add('error');
		} else {
			nameInput.classList.remove('error');
		}

		if (!phoneInput.value) {
			validForm = false;
			phoneInput.classList.add('error');
		} else {
			phoneInput.classList.remove('error');
		}

		if (!emailInput.value) {
			validForm = false;
			emailInput.classList.add('error');
		} else {
			emailInput.classList.remove('error');
		}

		if (!personsInput.value) {
			validForm = false;
			personsInput.classList.add('error');
		} else {
			personsInput.classList.remove('error');
		}

		if (!timeInput.value) {
			validForm = false;
			timeInput.classList.add('error');
		} else {
			timeInput.classList.remove('error');
		}

		if (!dateInput.value) {
			validForm = false;
			dateInput.classList.add('error');
		} else {
			dateInput.classList.remove('error');
		}

		if(dateInput.value) {
			const selectedDate = new Date(dateInput.value);
			selectedDate.setHours(0, 0, 0, 0);
			const dayOfWeekNumber = selectedDate.getDay();
			const schedule = var_from_php.schedule[`day_${dayOfWeekNumber}`];
			const isDayOff = schedule[`is_day_off_${dayOfWeekNumber}`];

			if(isDayOff && dayOfWeekNumber && checkError) {
				validForm = false;
				checkError.innerText = 'We are closed on this day of the week.';
				checkError.classList.add('show');
			}

			if(!isDayOff && timeInput.value && checkError) {
				const scheduleFromVal = schedule[`from_${dayOfWeekNumber}`];
				const scheduleToVal = schedule[`to_${dayOfWeekNumber}`];
				const scheduleFrom = new Date(`1970-01-01T${scheduleFromVal}`);
				const scheduleTo = new Date(`1970-01-01T${scheduleToVal}`);
				const fromVal = convertTime($(timeInput).timepicker('getTime'));
				const timeFromValue = new Date(`1970-01-01T${fromVal}`);

				if(timeFromValue < scheduleFrom) {
					validForm = false;
					timeInput.classList.add('error');
					checkError.innerText = `On this day we are open from ${scheduleFromVal} noon.`;
					checkError.classList.add('show');

				} else {
					timeInput.classList.remove('error');
				}

				if(timeFromValue > scheduleTo) {
					validForm = false;
					timeInput.classList.add('error');
					checkError.innerText = `On this day we are open until ${scheduleToVal}.`;
					checkError.classList.add('show');
				} else {
					timeInput.classList.remove('error');
				}
			}

			if (selectedDate < currentDate) {
				validForm = false;
				checkError.innerText = 'The selected date cannot be less than the current date!';
				checkError.classList.add('show');
				dateInput.classList.add('error');
			}

			if (selectedDate.getTime() === currentDate.getTime()) {
				validForm = false;
				checkError.innerText = 'You cannot book a table for the current day. Please call the restaurant +353 83 47 00 407';
				checkError.classList.add('show');
				dateInput.classList.add('error');
			}
		}

		if(validForm) {
			checkError.classList.remove('show');
			loader && loader.classList.add('show');

			const data = new FormData(event.target);
			data.append( 'action', 'book_party' );
			data.append( 'time', timeInput.value );

			fetch(var_from_php.ajax_url,
				{
					method: "POST",
					credentials: 'same-origin',
					body: data
				})
				.then((response) => response.json())
				.then((response) => {
					if(response.data.send) {
						loader && loader.classList.remove('show');
						form.reset();
						openSuccess();
					} else {
						loader && loader.classList.remove('show');
						checkError.innerText = 'There was a problem sending your message, please try again later';
						checkError.classList.add('show');
					}

				})
		}

	})

	openPopup && openPopup.forEach(item => {
		item.addEventListener('click', event => {
			event.preventDefault();
			const popupId = event.target.getAttribute('href');
			const currentPopup = document.querySelector(`${popupId}`);
			currentPopup && currentPopup.classList.add('show');
			removeScroll();
		})
	})

	closePopupButton && closePopupButton.forEach(item => {
		item.addEventListener('click', event => {
			event.preventDefault();
			closePopup();
		})
	})

	async function validAdminReserve () {
		if(!adminReserveForm) return;

		let isValid = true;

		const dateInput      = adminReserveForm.querySelector('input[name="date"]');
		const tableInput     = adminReserveForm.querySelector('input[name="table"]');
		const nameInput      = adminReserveForm.querySelector('input[name="name"]');
		const emailInput     = adminReserveForm.querySelector('input[name="email"]');
		const phoneInput     = adminReserveForm.querySelector('input[name="phone"]');
		const fromInput      = adminReserveForm.querySelector('input[name="from"]');
		const toInput        = adminReserveForm.querySelector('input[name="to"]');
		const adultsSelect   = adminReserveForm.querySelector('select[name="adults"]');
		const childrenSelect = adminReserveForm.querySelector('select[name="children"]');
		const personError    = adminReserveForm.querySelector('.js-person-error');
		const tableSelect    = adminReserveForm.querySelector('select[name="table[]"]');
		

		if(!dateInput || !nameInput || !emailInput || !phoneInput || !fromInput || !toInput || !adultsSelect || !childrenSelect || !checkError) return;

		const adults       = parseInt(adultsSelect.value);
		const children     = parseInt(childrenSelect.value);
		const maxPlace     = parseInt(adminReserveForm.dataset.place);
		const selectedDate = new Date(dateInput.value);
		const currentDate  = new Date();
		currentDate.setHours(0, 0, 0, 0);
		const fromVal = convertTime($(fromInput).timepicker('getTime'));
		const toVal = convertTime($(toInput).timepicker('getTime'));
		const timeFromValue = new Date(`1970-01-01T${fromVal}`);
		const timeToValue   = new Date(`1970-01-01T${toVal}`);

		if( dateInput.value  && (fromInput.value || toInput.value)) {
			const dayOfWeekNumber = selectedDate.getDay();
			const schedule = var_from_php.schedule[`day_${dayOfWeekNumber}`];
			const isDayOff = schedule[`is_day_off_${dayOfWeekNumber}`];

			if(!isDayOff) {
				const scheduleFromVal = schedule[`from_${dayOfWeekNumber}`];
				const scheduleToVal = schedule[`to_${dayOfWeekNumber}`];
				const scheduleFrom = new Date(`1970-01-01T${scheduleFromVal}`);
				const scheduleTo = new Date(`1970-01-01T${scheduleToVal}`);

				if(timeFromValue < scheduleFrom) {
					/*isValid = false;
					fromInput.classList.add('error');
					checkError.innerText = `On this day we are open from ${scheduleFromVal} noon.`;
					checkError.classList.add('show');*/

				} else if(timeToValue > scheduleTo || timeToValue < scheduleFrom) {
					/*isValid = false;
					toInput.classList.add('error');

					if(timeToValue > scheduleTo) {
						checkError.innerText = `On this day we are open until ${scheduleToVal}.`;
					}

					if(timeToValue < scheduleFrom) {
						checkError.innerText = `On this day we are open from ${scheduleFromVal} noon.`;
					}

					checkError.classList.add('show');*/

				} else if (timeToValue <= timeFromValue) {
					/*toInput.classList.add('error');
					isValid = false;

					checkError.innerText = 'The end time of the booking cannot be earlier than or equal to the start time of the booking.';
					checkError.classList.add('show');*/

				} else if(!fromInput.value) {
					isValid = false;
					fromInput.classList.add('error');
					checkError.innerText = 'From field is required!';
					checkError.classList.add('show');
				}  else {
					fromInput.classList.remove('error');
				}

			}
		}

		/*if( (children+adults) >  maxPlace) {
			isValid = false;
			personError.querySelector('span').innerText = maxPlace;
			personError && personError.classList.add('show');
		} else {
			personError && personError.classList.remove('show');
		}*/

		if(!dateInput.value) {
			isValid = false;
			dateInput.classList.add('error');
			checkError.innerText = 'Date field is required!';
			checkError.classList.add('show');
		} else {
			dateInput.classList.remove('error');
		}

		if(!nameInput.value) {
			isValid = false;
			nameInput.classList.add('error');
			checkError.innerText = 'Name field is required!';
			checkError.classList.add('show');
		} else {
			nameInput.classList.remove('error');
		}
		
		/*if(!emailInput.value) {
			isValid = false;
			emailInput.classList.add('error');
			checkError.innerText = 'Email field is required!';
			checkError.classList.add('show');
		} else {
			emailInput.classList.remove('error');
		}*/

		if(!phoneInput.value) {
			isValid = false;
			phoneInput.classList.add('error');
			checkError.innerText = 'Phone field is required!';
			checkError.classList.add('show');
		} else {
			phoneInput.classList.remove('error');
		}

		if(!toInput.value) {
			isValid = false;
			toInput.classList.add('error');
			checkError.innerText = 'To field is required!';
			checkError.classList.add('show');
		} else {
			toInput.classList.remove('error');
		}

		if(adultsSelect.value == 0) {
			isValid = false;
			adultsSelect.classList.add('error');
			checkError.innerText = 'Adults field is required!';
			checkError.classList.add('show');
		} else {
			adultsSelect.classList.remove('error');
		}
		
		if(!tableSelect.value) {
			isValid = false;
			tableSelect.classList.add('error');
			checkError.innerText = 'Tables field is required!';
			checkError.classList.add('show');
		} else {
			adultsSelect.classList.remove('error');
		}

		/*if(dateInput.value) {
			const dayOfWeekNumber = selectedDate.getDay();
			const schedule = var_from_php.schedule[`day_${dayOfWeekNumber}`];
			const isDayOff = schedule[`is_day_off_${dayOfWeekNumber}`];

			if(isDayOff && dayOfWeekNumber) {
				isValid = false;
				checkError.innerText = 'We are closed on this day of the week.';
				checkError.classList.add('show');
			}
		}*/

		/*if(fromInput.value && toInput.value && dateInput.value && tableInput.value) {
			const checkReserve = await checkReserveTable(fromInput.value, toInput.value, dateInput.value, tableInput.value);
			if(!checkReserve) {
				isValid = false
			}
		}*/

		return isValid;
	}

	async function checkReserveTable(from, to, date, table) {
		if(!from || !to || !date || !table) return;
		const data = new FormData(event.target);
		data.append( 'action', 'search_reservation' );
		data.append( 'date', date );
		data.append( 'table_number', table );
		data.append( 'time_from', from );
		data.append( 'time_to', to );

		const response = await fetch(var_from_php.ajax_url, {
			method: "POST",
			credentials: 'same-origin',
			body: data
		});

		const responseData = await response.json();

		if (responseData.success) {
			const reserveArr = responseData.data.reserve;

			if(reserveArr.length) {
				const reserveLine = reserveArr.join(', ');
				checkError.innerText = `The table is already reserved for this date. Reservation ID - ${reserveLine}`;
				checkError.classList.add('show');
				return false;
			} else {
				return true;
			}
		}

	}

	function validReserveForm () {

		if(!reserveForm || !reserveTableForm || !timeFrom || !timeTo ) return;
		const dateInput = reserveForm.querySelector('input[name="date"]');
		let isValid = true;
		const timeInput = reserveForm.querySelector('input[name="time-from"]');
		const timeFromInput = reserveForm.querySelector('input[name="time-from"]');
		const timeToInput = reserveForm.querySelector('input[name="time-to"]');
		const tableNumber = event.target.dataset.table;
		const tableInput =  reserveTableForm.querySelector('input[name="table"]');
		const place = event.target.dataset.places;
		const selectedDate = new Date(dateInput.value);
		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);

		if(place) reserveTableForm.dataset.place = place;

		if(tableInput && tableNumber) tableInput.value = tableNumber;

		if(!dateInput.value || !timeInput.value || !timeFromInput.value || !timeToInput.value) {
			isValid = false;
			error.innerText = 'To order a table, you need to fill in all fields!';
			error.classList.add('show');
		}

		if(dateInput.value) {
			const dayOfWeekNumber = selectedDate.getDay();
			const schedule = var_from_php.schedule[`day_${dayOfWeekNumber}`];
			const isDayOff = schedule[`is_day_off_${dayOfWeekNumber}`];

			if(isDayOff && dayOfWeekNumber) {
				isValid = false;
				error.innerText = 'We are closed on this day of the week.';
				error && error.classList.add('show');
			}
		}

		if(convertDate(selectedDate) == convertDate(currentDate)) {
			isValid = false;
			error.innerHTML = 'You cannot book a table for the current day. Please call the restaurant <a href="tel:+353834700407">+353 83 47 00 407</a>';
			error && error.classList.add('show');
		}

		if (selectedDate < currentDate) {
			isValid = false;
			error.innerText = 'The selected date cannot be less than the current date!';
			error && error.classList.add('show');
		}

		if( !checkReserveTime() ) {
			isValid = false;
		}

		if(isValid) {
			error && error.classList.remove('show');
		}

		return isValid;
	}

	function closePopup () {
		popups && popups.forEach(item => {
			const form = item.querySelector('form');
			const disableDaysWrap = item.querySelector('.js-disable-days');

			if(disableDaysWrap) {
				disableDaysWrap.style.display = 'none';
			}

			if(form) {
				form && form.reset();
				const errorInput = form.querySelectorAll('input');
				const errorSelect = form.querySelectorAll('select');

				errorSelect && errorSelect.forEach(item => {
					item.classList.remove('error');
				})

				errorInput && errorInput.forEach(item => {
					item.value = '';
					item.classList.remove('error');
				})
			}

			checkError && checkError.classList.remove('show');
			error && error.classList.remove('show');
			item.classList.remove('show');
		});

		addScroll();
	}

	function removeScroll () {
		const scrollBody = window.scrollY
		body.dataset.scroll = scrollBody;
		body.style.position = 'fixed';
		body.style.top = `-${scrollBody}px`;
	}

	function addScroll () {
		const scroll = parseInt(body.dataset.scroll);
		body.style.position = 'relative';
		body.style.top = 'auto';
		window.scroll({top: scroll, left: 0});
		body.removeAttribute('data-scroll');
	}

	function openSuccess () {
		closePopup();
		successMess && successMess.classList.add('show');
		removeScroll();
	}

	function convertTime (originalTime) {

		if(!originalTime) return '';

		let dateObj = new Date(originalTime);
		let hours = dateObj.getHours();
		let minutes = dateObj.getMinutes();

		return hours + ':' + (minutes < 10 ? '0' : '') + minutes;
	}

	function convertDate(date) {
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		const formattedDate = `${day} ${month} ${year}`;
		return formattedDate;
	}

	function checkReserveTime () {

		if(!timeFrom || !timeTo || !reserveForm) return;

		const dateInput = reserveForm.querySelector('input[name="date"]');

		let valid = true;

		const selectedDate    = new Date(dateInput.value);
		const currentDate     = new Date();
		const dayOfWeekNumber = selectedDate.getDay();
		const fromValue       = convertTime($(timeFrom).timepicker('getTime'));
		const toValue         = convertTime($(timeTo).timepicker('getTime'));
		const timeFromValue = new Date(`1970-01-01T${fromValue}`);
		const timeToValue = new Date(`1970-01-01T${toValue}`);
		let timeDiff          = Math.abs(timeToValue - timeFromValue);
		let twoHoursInMillis  = 2 * 60 * 60 * 1000;
		let oneHoursInMillis  = 1 * 60 * 60 * 1000;

		if( dateInput.value  && (fromValue || toValue)) {
			const schedule = var_from_php.schedule[`day_${dayOfWeekNumber}`];
			const isDayOff = schedule[`is_day_off_${dayOfWeekNumber}`];

			if(!isDayOff) {
				const scheduleFromVal = schedule[`from_${dayOfWeekNumber}`];
				const scheduleToVal = schedule[`to_${dayOfWeekNumber}`];
				const scheduleFrom = new Date(`1970-01-01T${scheduleFromVal}`);
				const scheduleTo = new Date(`1970-01-01T${scheduleToVal}`);

				if(timeFromValue < scheduleFrom) {
					valid = false;
					timeFrom.classList.add('error');
					if(error) {
						error.innerText = `On this day we are open from ${scheduleFromVal} noon.`;
						error.classList.add('show');
					}
				} else {
					timeFrom.classList.remove('error');
				}

				if(timeToValue > scheduleTo || timeToValue < scheduleFrom) {
					valid = false;
					timeTo.classList.add('error');
					if(error) {
						if(timeToValue > scheduleTo) {
							error.innerText = `On this day we are open until ${scheduleToVal}.`;
						}

						if(timeToValue < scheduleFrom) {
							error.innerText = `On this day we are open from ${scheduleFromVal} noon.`;
						}

						error.classList.add('show');
					}
				} else {
					timeTo.classList.remove('error');
				}
			}
		}


		if (timeToValue <= timeFromValue) {
			timeTo.classList.add('error');
			valid = false;
			if(error) {
				error.innerText = 'The end time of the booking cannot be earlier than or equal to the start time of the booking.';
				error.classList.add('show');
			}
		} else {
			timeTo.classList.remove('error');
		}

		if(timeDiff > twoHoursInMillis) {
			error.innerText = 'Sorry, you cannot book a table for more than 2 hours.';
			error.classList.add('show');
			valid = false;
		} else {
			timeTo.classList.remove('error');
		}

		if(timeDiff < oneHoursInMillis) {
			error.innerText = 'Sorry, you cannot book a table for less than 1 hour.';
			error.classList.add('show');
			valid = false;
		} else {
			timeTo.classList.remove('error');
		}

		return valid;
	}

	async function checkTable () {
		if(!timeFrom || !timeTo || !reserveDateInput) return;

		tableItems && tableItems.forEach(item => {
			item.classList.remove('active');
		})

		const from = timeFrom.value
		const to = timeTo.value;
		const date = reserveDateInput.value;

		if((from || to) && date) {
			const data = new FormData();
			data.append( 'action', 'check_table' );
			data.append( 'from', from );
			data.append( 'to', to );
			data.append( 'date', date );

			const response = await fetch(var_from_php.ajax_url, {
				method: "POST",
				credentials: 'same-origin',
				body: data
			});

			const responseData = await response.json();

			if (responseData.success) {
				const tablesArr = responseData.data.tables;

				tablesArr.forEach( item => {
					const arr = item.split(",");

					arr.forEach(item => {
						const tableElem = document.querySelector(`.table-${item}`);
						tableElem && tableElem.classList.add('active');
					})
				})
			}
		}
	}

	animateBlock && [...animateBlock].forEach( ( item ) => {
		if ( isInViewport( item, 100) ) {
			item.classList.add('animate');
		}
	} );

	/**
	 * Scroll handler
	 */
	window.addEventListener( 'scroll', ( event ) => {
		animateBlock && [...animateBlock].forEach( ( item ) => {
			if ( isInViewport( item, 100 ) ) {
				item.classList.add('animate');
			} else {
				item.classList.remove('animate');
			}
		} );

	} );

	anchorLinkScroll('.anchor a');

	scrollTop && scrollTop.addEventListener('click', event => {
		event.preventDefault();
		window.scroll( {
			'behavior': 'smooth',
			'left': 0,
			'top': 0
		} );
	})

	forms && forms.forEach(item => {
		item.addEventListener('wpcf7mailsent',event => {

		})
	})




	/**
	 * Occurs when all HTML has been fully loaded and passed by the parser,
	 * without waiting for the stylesheets, images and frames to finish loading
	 */
	document.addEventListener("DOMContentLoaded", function(event) {
		const inputTimeFrom = document.querySelector('#reserve-admin-table-form input[name="from"]');
		
		inputTimeFrom && $(inputTimeFrom).on('change', (event) => {
			const fromTime = $(event.target).val();
			const toTimeInput = document.querySelector('#reserve-admin-table-form input[name="to"]');
			
			if(fromTime && toTimeInput) {
				const calcTimeTo = addHoursToTime(fromTime, 2);
				toTimeInput.value = calcTimeTo;
			}
		})
		
		
		function addHoursToTime(time, hoursToAdd) {
			let [hours, minutes] = time.split(':').map(Number);
			hours += hoursToAdd;
			hours = hours % 24;
			let formattedHours = hours.toString().padStart(2, '0');
			let formattedMinutes = minutes.toString().padStart(2, '0');
			return `${formattedHours}:${formattedMinutes}`;
		}


		/**
		 * Add global handler for click event
		 * The main idea - to improve site performance
		 * We added only 1 event handler to "click" event
		 * and then use date-role attribute on each( needed ) elements
		 * to define on which element event was executed..
		 */
		document.body.addEventListener( 'click', event => {
			const ROLE = event.target.dataset.role;
			const clickElem = event.target;

			if ( !ROLE ) return;

			switch ( ROLE ) {
				case 'disable-table' : {
					event.preventDefault();
					const tableNumber = clickElem.dataset.table;
					const currentDate = clickElem.dataset.date;
					if(!tableNumber) return;

					if (!clickElem.classList.contains('active')) {
						const removePopup = document.querySelector('#disable-table-popup');
						if(!removePopup) return;

						const inputTable       = removePopup.querySelector('input[name="table"]');
						const inputCurrentDate = removePopup.querySelector('input[name="current-date"]');
						if(!inputTable || !inputCurrentDate) return;

						inputTable.value = tableNumber
						inputCurrentDate.value = currentDate
						removeScroll();
						removePopup.classList.add('show');
					} else {

						const form = document.querySelector('#remove-disable-table-form');
						if(!form) return;
						const tableInput = form.querySelector('input[name="table"]');
						const dateInput = form.querySelector('input[name="current-date"]');
						if(!tableInput || !dateInput) return;
						tableInput.value = tableNumber;
						dateInput.value = currentDate;

						const data = new FormData();
						data.append( 'action', 'remove_disable_table' );
						data.append( 'tableNumber', tableNumber );


						fetch(var_from_php.ajax_url,
							{
								method: "POST",
								credentials: 'same-origin',
								body: data
							})
							.then((response) => response.json())
							.then((responseData) => {
								if(responseData.success) {
									const data = responseData.data;

									if(data.type === 'date') {
										const popup = document.querySelector('#remove-disable-table-popup');
										if(!popup) return;
										const content = popup.querySelector('.js-remove-disable-content');
										if(!content) return;
										content.innerHTML = data.html;
										const removeSelect = content.querySelector('.js-delete-disable-select');
										if ( !removeSelect ) return;

										$(removeSelect).select2({
											placeholder: "Select range",
											allowClear: true
										});

										popup && popup.classList.add('show');
										removeScroll();
									}

									if(data.type === 'full') {
										const elemTable = document.querySelector(`.disable-table[data-table="${data.table}"]`);
										elemTable && elemTable.classList.remove('active');
									}
								}
							})
					}


				}
				break;
				case 'close-popup': {
					event.preventDefault();
					closePopup();
				}
					break;
				case 'get-date':
				{
					event.preventDefault();
					const monthWrap = document.querySelector('.js-month-wrap');
					const yearWrap = document.querySelector('.js-year-wrap');
					const daysWrap = document.querySelector('.js-days-wrap');
					if(!monthWrap || !yearWrap) return;
					const status = clickElem.closest('.js-admin-wrap').dataset.status;
					const day = clickElem.dataset.day;
					const month = monthWrap.querySelector('.admin-table__link.active').dataset.month;
					const year = yearWrap.querySelector('li.active').dataset.year;
					getTableReserves (day, month, year, status)
				}
					break;
				case 'get-month':
				{
					event.preventDefault();
					const monthWrap = document.querySelector('.js-month-wrap');
					const yearWrap = document.querySelector('.js-year-wrap');
					const daysWrap = document.querySelector('.js-days-wrap');
					if(!monthWrap || !yearWrap || !daysWrap) return;
					const status = clickElem.closest('.js-admin-wrap').dataset.status;
					const month = clickElem.dataset.month;
					const day = daysWrap.querySelector('.admin-table__day.active').dataset.day;
					const year = yearWrap.querySelector('li.active').dataset.year;
					getTableReserves (day, month, year, status)
				}
					break;
				case 'get-year':
				{
					event.preventDefault();
					const monthWrap = document.querySelector('.js-month-wrap');
					const yearWrap = document.querySelector('.js-year-wrap');
					const daysWrap = document.querySelector('.js-days-wrap');
					if(!monthWrap || !yearWrap || !daysWrap) return;
					const status = clickElem.closest('.js-admin-wrap').dataset.status;
					const month = monthWrap.querySelector('.admin-table__link.active').dataset.month;
					const day = daysWrap.querySelector('.admin-table__day.active').dataset.day;
					const year = clickElem.dataset.year;
					getTableReserves (day, month, year, status);
				}
					break;
				case 'show-reserve':
				{
					event.preventDefault();
					const postId = clickElem.dataset.id;
					const tableItems = document.querySelectorAll('.admin-table__table .table-time');
					const adminTable = document.querySelector('.js-admin-table');
					const tableNumbers = clickElem.dataset.table;
					let tableArray = tableNumbers.split(",");
					
					tableItems && tableItems.forEach(item => {
						item.classList.remove('show');
					});
					
					const reserve = document.querySelectorAll(`.admin-table__table .table-time[data-id="${postId}"]`);
					
					reserve && reserve.forEach(item => {
						item.classList.add('show');
					});
					
					if(adminTable) {
						 let elementPosition = adminTable.getBoundingClientRect().top;
						 let offsetPosition = elementPosition + window.pageYOffset - 150;
						 
						 window.scrollTo({
						  top: offsetPosition,
						  behavior: 'smooth'
						 });
				  }
				}
				break
				case 'edit-reserve':
				{
					event.preventDefault();
					const editPopup = document.querySelector('#edit-admin-table');
					if(!editPopup) return;

					const date = clickElem.dataset.date;
					const email = clickElem.dataset.email;
					const phone = clickElem.dataset.phone;
					const adults = clickElem.dataset.adults;
					const children = clickElem.dataset.children;
					const comment = clickElem.dataset.comment;
					const table = clickElem.dataset.table;
					const from = clickElem.dataset.from;
					const to = clickElem.dataset.to;
					const name = clickElem.dataset.name;
					const postId = clickElem.dataset.id;
					const tableSelect = editPopup.querySelector('select[name="table[]"]');
					const tableArray = table.split(',');

					$(tableSelect).val(tableArray).trigger('change');
					editPopup.querySelector('input[name="date"]').value = date;
					editPopup.querySelector('input[name="name"]').value = name;
					editPopup.querySelector('input[name="email"]').value = email;
					editPopup.querySelector('input[name="phone"]').value = phone;
					editPopup.querySelector('input[name="from"]').value = from;
					editPopup.querySelector('input[name="to"]').value = to;
					editPopup.querySelector('select[name="adults"]').value = adults;
					editPopup.querySelector('select[name="children"]').value = children;
					editPopup.querySelector('textarea[name="comment"]').value = comment;
					editPopup.querySelector('input[name="reserve_id"]').value = postId;
					editPopup.querySelector('input[name="current_data"]').value = date;

					editPopup && editPopup.classList.add('show');
					removeScroll();
				}
				break
				case 'book-reserve' :
				{
					event.preventDefault();
					const bookId = clickElem.dataset.id;
					const date = clickElem.dataset.date;
					const dateArr = date.split('-');
					if( !bookId || !date) return;
					const day = dateArr[2];
					const year = dateArr[0];
					const month = dateArr[1];

					const data = new FormData();
					data.append( 'action', 'book_reserve' );
					data.append( 'bookId', bookId);

					fetch(var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						})
						.then((response) => response.json())
						.then((data) => {
							getTableReserves (day, month, year);
						})
				}
				break;
				case 'void-reserve':
				{
					event.preventDefault();

					const bookId = clickElem.dataset.id;
					const date = clickElem.dataset.date;
					const dateArr = date.split('-');
					if( !bookId || !date) return;
					const day = dateArr[2];
					const year = dateArr[0];
					const month = dateArr[1];

					const data = new FormData();
					data.append( 'action', 'book_void' );
					data.append( 'bookId', bookId);

					fetch(var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						})
						.then((response) => response.json())
						.then((data) => {
							getTableReserves (day, month, year);
						})
				}
				break;
				case 'create-table':
				{
					event.preventDefault();
					const exportDate = clickElem.dataset.date;

					if(!exportDate) return;

					const data = new FormData();
					data.append( 'action', 'export_table' );
					data.append( 'date', exportDate);

					fetch(var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						})
						.then((response) => response.json())
						.then((responseData) => {
							if(responseData.success) {
								const url = responseData.data.url;
								window.open(url, "_blank");
							}
						})

				}
				break;
				case 'add-reserve':
				{
					event.preventDefault();
					if(!adminReservePopup) return;
					const table = clickElem.dataset.table;
					const date = clickElem.dataset.date;
					const timeFrom = clickElem.dataset.time;
					const calcTimeTo = addHoursToTime(timeFrom, 2);
					
					const places = clickElem.dataset.places;
					const tableInput = adminReserveForm.querySelector('input[name="table"]');
					const dateInput = adminReserveForm.querySelector('input[name="date"]');
					const timeFromInput = adminReserveForm.querySelector('input[name="from"]');
					const timeToInput = adminReserveForm.querySelector('input[name="to"]');
					adminReserveForm.dataset.place = places;
					if(tableInput) tableInput.value = table;
					if(dateInput) dateInput.value = date;
					if(timeFromInput) timeFromInput.value = timeFrom;
					if(timeToInput) timeToInput.value = calcTimeTo;

					const reservePopupAdmin = document.querySelector('#reserve-admin-table');
					removeScroll();
					reservePopupAdmin.classList.add('show');
				}
				break
				case 'restore-reserve':
				{
					event.preventDefault();

					const restoreId = clickElem.dataset.id;
					const date = clickElem.dataset.date;
					const dateArr = date.split('-');
					const day = dateArr[2];
					const year = dateArr[0];
					const month = dateArr[1];
					const status = clickElem.closest('.js-admin-wrap').dataset.status;

					const data = new FormData();
					data.append( 'action', 'restore_reserve' );
					data.append( 'restore_id', restoreId);

					fetch(var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						})
						.then((response) => response.json())
						.then((data) => {
							if(data.success) {
								getTableReserves (day, month, year, status);
							} else {
								const restorePopup = document.querySelector('#restore-popup');
								restorePopup.querySelector('.js-restore-error-id').innerText = data.data.reserve;
								restorePopup.classList.add('show');
								removeScroll()
							}

						})


				}
				break;
				case 'remove-reserve':
				{
					event.preventDefault();

					const removeId = clickElem.dataset.id;
					const date = clickElem.dataset.date;
					const dateArr = date.split('-');
					const day = dateArr[2];
					const year = dateArr[0];
					const month = dateArr[1];
					const status = clickElem.closest('.js-admin-wrap').dataset.status;

					const data = new FormData();
					data.append( 'action', 'remove_reserve' );
					data.append( 'remove_id', removeId);

					fetch(var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						})
						.then((response) => response.json())
						.then((data) => {
							getTableReserves (day, month, year, status);
						})
				}
				break;
			}
		} );

	});

	function getTableReserves (day, month, year, status='publish') {
		const loader = document.querySelector('.js-admin-loader');

		loader && loader.classList.add('show');

		const data = new FormData();
		data.append( 'action', 'get_reserve' );
		data.append( 'day', day.toString().padStart(2, '0') );
		data.append( 'month', month.toString().padStart(2, '0') );
		data.append( 'year', year );
		data.append( 'status', status );

		fetch(var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			})
			.then((response) => response.json())
			.then((responseData) => {
				if(!responseData.success) return;

				const content = responseData.data.content;
				const reserveTable =  document.querySelector('.js-admin-table-wrap');

				if(reserveTable) {
					reserveTable.innerHTML = content;
				}

				loader && loader.classList.remove('show');
			})
	}

	// Use it when you need to know that everything is loaded (html, styles, images)
	window.addEventListener('load', (event) => {
		

		/**
		 * Simple hack for some cases
		 */
		setTimeout( () => {
			document.body.classList.add( 'loaded' );
			console.log('load');
		}, 500 );


	});

})( jQuery );